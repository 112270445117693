import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Bildungsexpertise</h1>
        <h2>Hier entsteht eine Plattform mit Menschen, die für innovative Bildung brennen und Lösungsideen für den Bildungsstandort Deutschland entwickeln. Laden Sie sie in Ihre Sendung, in ihr Panel, auf die Bühne oder als kluge Mitdenkerinnen und Mitdenker in Ihre Diskussion ein.</h2>
      </header>
    </div>
  );
}

export default App;
